<template>
  <AdapterFrame class="bg-gray-50">
    <van-cell size="large" class="top-0 sticky z-10" :border="false">
      <template #title>
        <div @click="showPopup = true" class="whitespace-nowrap">
          <span>选择月份：</span>
          <span>{{ slectedDate | formatDate }}</span>
        </div>
      </template>
      <van-icon name="replay" size="1rem" class="font-black" @click="listFresh" />
    </van-cell>
    <van-list
      :key="freshKey"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      class="mb-10"
      @load="onLoad(curPage,pageSize,slectedDate)"
    >
      <van-cell v-for="item in list" :key="item" :title="item" />
    </van-list>
    <div class="absolute bottom-0 flex w-full flex-row justify-evenly bg-white">
      <div class="flex flex-col items-center">
        <van-icon name="like-o" class="text-green-400 text-lg"/>
        <div class="text-green-400 text-xs">喜欢</div>
      </div>
      <div class="flex flex-col items-center justify-end" @click="logout">
        <img :src="iconHome" class="my-1 w-5" alt="" />
        <div class="text-green-400 text-xs">退出</div>
      </div>
      <div class="flex flex-col items-center">
        <van-icon name="star-o" class="text-green-400 text-lg" />
        <div class="text-green-400 text-xs">收藏</div>
      </div>
    </div>
    <van-popup v-model="showPopup" round position="bottom" class="h-2/6" :close-on-click-overlay="false">
      <van-datetime-picker
        @confirm="setSlectedDate"
        @cancel="showPopup = false"
        item-height="2rem"
        v-model="slectedDate"
        type="year-month"
        title="选择年月"
      />
    </van-popup>
  </AdapterFrame>
</template>

<script>
import AdapterFrame from '@/components/AdapterFrame.vue';
import {pageTo} from '@/utils/router.js';
import {store2sessionStorage} from '@/utils/syncStore.js';
import {list} from '@/api';
export default {
  name: 'Home',
  components: {
    AdapterFrame,
  },
  data() {
    return {
      freshKey: 0,
      total: '',
      iconHome: require('@/assets/img/logo.png'),
      list: [],
      loading: false,
      finished: false,
      slectedDate: new Date(),
      showPopup: false,
      curPage: 1,
      pageSize: 10,
    };
  },
  methods: {
    setSlectedDate(val) {
      this.slectedDate = val;
      this.listFresh();
      this.showPopup = false;
    },
    async getLog(curPage, pageSize, time) {
      const params = {
        currentPage: curPage,
        pageSize: pageSize,
        time: this.$moment(time).format('YYYY-MM'),
      };
      return await list(params).then((res) => {
        return [
          res.hasNextPage,
          res.list,
        ];
      });
    },
    async onLoad(curPage, pageSize, time) {
      // 异步更新数据
      const [hasNextPage, nextList] = await this.getLog(curPage, pageSize, time);
      this.curPage++;
      this.loading = false;
      this.finished = !hasNextPage;
      this.list = this.list.concat(nextList);
    },
    listFresh() {
      this.list = [];
      this.curPage = 1;
      this.finished = false;
      this.freshKey++;
    },
    logout() {
      this.$store.commit('setToken', "");
      store2sessionStorage();
      pageTo('login');
    },
  },
};
</script>

<style scoped></style>

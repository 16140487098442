// import http from '@/plugins/axios.js';
import {hexMD5} from '@/utils/md5.js';
// import qs from 'qs';

// 登录
export function login(params, header) {
  return Promise.resolve({
    token: hexMD5(params.loginPwd),
  });
  // const result = http.post(
  //   '/app/login',
  //   params,
  //   header,
  // );
  // return result;
}

// 列表
export function list(params, header) {
  const tempList = [];
  for (let i = 0; i < params.pageSize; i++) {
    tempList.push(`${params.time}：${params.currentPage * params.pageSize + i}`);
  }
  return Promise.resolve({
    hasNextPage: params.currentPage < 5,
    list: tempList,
  });
  // const result = http.get(
  //   '/app/list',
  //   params,
  //   header,
  // );
  // return result;
}
